<template>
  <div
    class="base-transport-route-label"
    :style="labelStylesheet"
  >

    <investments-investment-label
      :investment="start"
      v-if="start"
      :size="size"
    />
    <div v-else class="no-investment">BAZA</div>

    <feather-icon icon="ArrowRightIcon" :size="style.arrowSize" />

    <investments-investment-label
      :investment="destination"
      :size="size"
      v-if="destination"
    />
    <div v-else class="no-investment">BAZA</div>

  </div>
</template>

<script>
import InvestmentsInvestmentLabel from '@/components/views/investments/InvestmentsInvestmentLabel.vue';
import { computed } from 'vue';

export default {
  name: 'BaseTransportRouteLabel',
  props: {
    start: {
      type: Object,
    },
    destination: {
      type: Object,
    },
    size: {
      type: String,
      validator: (val) => ['sm', 'md', 'lg'].includes(val),
      default: 'md',
    },
  },
  setup(props) {
    const sizeStylings = {
      sm: {
        arrowSize: '14',
      },
      md: {
        arrowSize: '14',
      },
      lg: {
        arrowSize: '20',
        gap: '10px',
      },
    };

    const style = computed(() => sizeStylings[props.size]);
    const labelStylesheet = computed(() => ({
      '--gap': style.value.gap ?? '5px',
    }));

    return { style, labelStylesheet };
  },
  components: {
    InvestmentsInvestmentLabel,
  },
};
</script>

<style lang="scss" scoped>
.base-transport-route-label {
  --gap: 5px;

  display: flex;
  align-items: center;
  gap: var(--gap);
}
</style>
