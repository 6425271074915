<script>
import initializePickerComponent from '@/components/ui/vs-picker';
import useBaseApi from '@/hooks/base/useBaseApi';
import { ref } from 'vue';

const pickerList = ref([]);

export default initializePickerComponent('BaseTransportTypePicker', {
  props: {
    reduce: (option) => option.id,
    placeholder: 'Wybierz rodzaj transportu z listy',
  },
  options: pickerList,
  fetchFunction: () => {
    useBaseApi()
      .indexTransportTypes()
      .then(({ data }) => {
        pickerList.value = data.data;
      })
      .catch(() => {});
  },
});
</script>
