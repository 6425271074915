import { defineStore } from 'pinia';
import { ref } from 'vue';

export default defineStore('baseTransportStatus', () => {
  const state = ref([
    { name: 'Nowy', slug: 'new', color: '#ffe224' },
    { name: 'W drodze', slug: 'onTheWay', color: '#ff8d29' },
    { name: 'Dostarczono', slug: 'delivered', color: '#31c458' },
  ]);

  return {
    state,
  };
});
