var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-transport-route-label",
    style: _vm.labelStylesheet
  }, [_vm.start ? _c('investments-investment-label', {
    attrs: {
      "investment": _vm.start,
      "size": _vm.size
    }
  }) : _c('div', {
    staticClass: "no-investment"
  }, [_vm._v("BAZA")]), _c('feather-icon', {
    attrs: {
      "icon": "ArrowRightIcon",
      "size": _vm.style.arrowSize
    }
  }), _vm.destination ? _c('investments-investment-label', {
    attrs: {
      "investment": _vm.destination,
      "size": _vm.size
    }
  }) : _c('div', {
    staticClass: "no-investment"
  }, [_vm._v("BAZA")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }