var render = function render(){
  var _vm$selectedResource$, _vm$selectedResource$2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vs-modal', {
    attrs: {
      "modalInstance": _vm.modalEditTransportResource
    },
    on: {
      "ok": _vm.createResource
    }
  }, [_c('vs-form', {
    attrs: {
      "errors": _vm.feedback,
      "i18nNamespace": "form.base.transport.resource"
    },
    model: {
      value: _vm.fields,
      callback: function ($$v) {
        _vm.fields = $$v;
      },
      expression: "fields"
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "id"
    },
    scopedSlots: _vm._u([_vm.selectedResource ? {
      key: "children",
      fn: function () {
        return [_c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "field": "status"
          }
        }, [_c('base-transport-resource-status-picker', {
          model: {
            value: _vm.fields.status,
            callback: function ($$v) {
              _vm.$set(_vm.fields, "status", $$v);
            },
            expression: "fields.status"
          }
        })], 1), _c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "field": "description"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "placeholder": "Uwagi do transportowanego zasobu"
          },
          model: {
            value: _vm.fields.description,
            callback: function ($$v) {
              _vm.$set(_vm.fields, "description", $$v);
            },
            expression: "fields.description"
          }
        })], 1)];
      },
      proxy: true
    } : null], null, true)
  }, [_c('base-resource-picker', {
    attrs: {
      "disabled": "",
      "labelProps": {
        withType: true
      },
      "excluded": _vm.shouldResourceBeExcluded
    },
    on: {
      "pick": function (resource) {
        return _vm.selectedResource = resource;
      }
    },
    model: {
      value: _vm.fields.id,
      callback: function ($$v) {
        _vm.$set(_vm.fields, "id", $$v);
      },
      expression: "fields.id"
    }
  }), _vm.selectedResource ? _c('div', {
    staticClass: "resource-details"
  }, [_c('div', {
    staticClass: "field"
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v("Wybrany zasób")]), _c('div', {
    staticClass: "value"
  }, [_c('base-resource-label', {
    attrs: {
      "resource": _vm.selectedResource
    }
  })], 1)]), _c('div', {
    staticClass: "field"
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v("Rodzaj zasobu")]), _vm.selectedResource.type ? _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.$t(`base.global.resourceType.${(_vm$selectedResource$ = _vm.selectedResource.type) === null || _vm$selectedResource$ === void 0 ? void 0 : _vm$selectedResource$.slug}`)) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "field"
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v("Nr ewidencyjny")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.selectedResource.serial_number) + " ")])]), _c('div', {
    staticClass: "field"
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v("Kategoria")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s((_vm$selectedResource$2 = _vm.selectedResource.category) === null || _vm$selectedResource$2 === void 0 ? void 0 : _vm$selectedResource$2.name) + " ")])])]) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }