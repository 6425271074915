<script>
import initializePickerComponent from '@/components/ui/vs-picker';
import store from '@/store';
import { computed } from 'vue';

const statuses = computed(() => store.getters['base/transport/resourceStatus/all']);

export default initializePickerComponent('BaseTransportResourceStatusPicker', {
  props: {
    reduce: (option) => option.slug,
    placeholder: 'Wybierz status transportu z listy',
    statusPicker: true,
  },
  options: statuses,
  fetchFunction: () => {},
});
</script>
