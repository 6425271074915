<template>
  <vs-modal
    :modalInstance="modalEditTransportResource"
    @ok="createResource"
  >
    <vs-form
      v-model="fields"
      :errors="feedback"
      i18nNamespace="form.base.transport.resource"
    >

      <vs-form-group field="id">

        <base-resource-picker
          v-model="fields.id"
          disabled
          :labelProps="{ withType: true }"
          :excluded="shouldResourceBeExcluded"
          @pick="(resource) => selectedResource = resource"
        />

        <div class="resource-details" v-if="selectedResource">

          <div class="field">
            <div class="name">Wybrany zasób</div>
            <div class="value">
              <base-resource-label :resource="selectedResource" />
            </div>
          </div>

          <div class="field">
            <div class="name">Rodzaj zasobu</div>
            <div class="value" v-if="selectedResource.type">
              {{ $t(`base.global.resourceType.${selectedResource.type?.slug}`) }}
            </div>
          </div>

          <div class="field">
            <div class="name">Nr ewidencyjny</div>
            <div class="value">
              {{ selectedResource.serial_number }}
            </div>
          </div>

          <div class="field">
            <div class="name">Kategoria</div>
            <div class="value">
              {{ selectedResource.category?.name }}
            </div>
          </div>
        </div>

        <template #children v-if="selectedResource">
          <vs-form-group labelCols="12" field="status">
            <base-transport-resource-status-picker
              v-model="fields.status"
            />
          </vs-form-group>

          <vs-form-group labelCols="12" field="description">
            <b-form-textarea
              v-model="fields.description"
              placeholder="Uwagi do transportowanego zasobu"
            />
          </vs-form-group>
        </template>

      </vs-form-group>

    </vs-form>

  </vs-modal>
</template>

<script>
import { BFormTextarea } from 'bootstrap-vue';
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import useValidatedForm from '@/hooks/useValidatedForm';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import BaseResourcePicker from '@/components/module/base/BaseResourcePicker.vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import { ref } from 'vue';
import BaseTransportResourceStatusPicker from '@/components/module/base/BaseTransportResourceStatusPicker.vue';
import BaseResourceLabel from '../../resource/BaseResourceLabel.vue';

export default {
  name: 'BaseTransportAddResourceModal',
  emits: ['ok'],
  props: {
    existingIds: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const { modalEditTransportResource } = useBaseModals();
    const continueEditing = ref(false);
    const selectedResource = ref(null);

    const {
      fields,
      feedback,
      areAllFieldsValid,
      resetFields,
      setFieldDefaults,
      assignFields,
    } = useValidatedForm({
      id: 'required',
      status: 'required',
      description: '',
    });
    setFieldDefaults({ status: 'readyToSend' });

    const resetForm = () => {
      selectedResource.value = null;
      resetFields();
    };

    modalEditTransportResource.value.on({
      open(data) {
        resetForm();
        assignFields(data);
      },
    });

    const createResource = async () => {
      if (!(await areAllFieldsValid())) return;

      emit('ok', {
        ...fields.value,
        details: {
          ...selectedResource.value,
        },
      });

      if (continueEditing.value) {
        resetForm();
      } else {
        modalEditTransportResource.value.close();
      }
    };

    const shouldResourceBeExcluded = (resource) => {
      const type = resource.type?.slug;
      if (!type) return true;

      if (props.existingIds?.find((id) => id === resource.id)) return true;

      return !['vehicle', 'tool'].includes(type);
    };

    return {
      modalEditTransportResource,
      continueEditing,
      fields,
      feedback,
      createResource,
      shouldResourceBeExcluded,
      selectedResource,
    };
  },
  components: {
    BFormTextarea,
    VsModal,
    VsForm,
    BaseResourcePicker,
    VsFormGroup,
    BaseResourceLabel,
    BaseTransportResourceStatusPicker,
  },
};
</script>

<style lang="scss" scoped>
.resource-details {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid var(--vs-card-border);
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  .field {
    .name {
      opacity: 0.5;
      font-size: 10pt;
    }
  }
}
</style>
