var render = function render(){
  var _vm$assignedResources, _vm$assignedResources2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vs-form', {
    attrs: {
      "i18nNamespace": "form.base.transport",
      "errors": _vm.feedback
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Nazwa zlecenia transportu"
    },
    model: {
      value: _vm.resource.name,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "name", $$v);
      },
      expression: "resource.name"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "status"
    }
  }, [_c('base-transport-status-picker', {
    on: {
      "pick": function (status) {
        return _vm.selectedStatus = status;
      },
      "input": _vm.userChangedStatus
    },
    model: {
      value: _vm.resource.status,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "status", $$v);
      },
      expression: "resource.status"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "crm_provider_id",
      "integration": "base_transport_company_relation"
    }
  }, [_c('crm-provider-picker', {
    model: {
      value: _vm.resource.crm_provider_id,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "crm_provider_id", $$v);
      },
      expression: "resource.crm_provider_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "base_transport_type_id"
    },
    scopedSlots: _vm._u([{
      key: "children",
      fn: function () {
        return [_c('div', {
          staticClass: "multi-form-group"
        }, [_c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "from_investment_id",
            "integration": "base_transport_investment_relation"
          }
        }, [_c('investments-investment-picker', {
          attrs: {
            "placeholder": "BAZA",
            "clearable": ""
          },
          model: {
            value: _vm.resource.from_investment_id,
            callback: function ($$v) {
              _vm.$set(_vm.resource, "from_investment_id", $$v);
            },
            expression: "resource.from_investment_id"
          }
        })], 1), _c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "to_investment_id",
            "integration": "base_transport_investment_relation"
          }
        }, [_c('investments-investment-picker', {
          attrs: {
            "placeholder": "BAZA",
            "clearable": ""
          },
          on: {
            "pick": function (investment) {
              return _vm.selectedDestination = investment;
            }
          },
          model: {
            value: _vm.resource.to_investment_id,
            callback: function ($$v) {
              _vm.$set(_vm.resource, "to_investment_id", $$v);
            },
            expression: "resource.to_investment_id"
          }
        })], 1)], 1), _c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "field": "core_branch_id"
          }
        }, [_c('core-branch-picker', {
          model: {
            value: _vm.resource.core_branch_id,
            callback: function ($$v) {
              _vm.$set(_vm.resource, "core_branch_id", $$v);
            },
            expression: "resource.core_branch_id"
          }
        })], 1), _c('div', {
          staticClass: "multi-form-group"
        }, [_c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "transport_date"
          }
        }, [_c('vs-datepicker', {
          model: {
            value: _vm.resource.transport_date,
            callback: function ($$v) {
              _vm.$set(_vm.resource, "transport_date", $$v);
            },
            expression: "resource.transport_date"
          }
        })], 1), _c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "delivery_date"
          }
        }, [_c('vs-datepicker', {
          model: {
            value: _vm.resource.delivery_date,
            callback: function ($$v) {
              _vm.$set(_vm.resource, "delivery_date", $$v);
            },
            expression: "resource.delivery_date"
          }
        })], 1)], 1), _c('div', {
          staticClass: "multi-form-group"
        }, [_c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "release_id"
          }
        }, [_c('core-user-picker', {
          model: {
            value: _vm.resource.release_id,
            callback: function ($$v) {
              _vm.$set(_vm.resource, "release_id", $$v);
            },
            expression: "resource.release_id"
          }
        })], 1), _c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "receiver_id"
          }
        }, [_c('core-user-picker', {
          model: {
            value: _vm.resource.receiver_id,
            callback: function ($$v) {
              _vm.$set(_vm.resource, "receiver_id", $$v);
            },
            expression: "resource.receiver_id"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('base-transport-type-picker', {
    model: {
      value: _vm.resource.base_transport_type_id,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "base_transport_type_id", $$v);
      },
      expression: "resource.base_transport_type_id"
    }
  })], 1), _c('div', {
    staticClass: "transport-resources"
  }, [_c('div', {
    staticClass: "transport-resources-header"
  }, [_c('vs-form-group', {
    attrs: {
      "field": "resources"
    }
  }, [_c('vs-button', {
    staticClass: "add-transport-resource",
    attrs: {
      "vs-variant": "light"
    },
    on: {
      "click": _vm.openAddResourceModal
    }
  }, [_vm._v(" + Dodaj zasób ")])], 1)], 1), (_vm$assignedResources = _vm.assignedResources) !== null && _vm$assignedResources !== void 0 && _vm$assignedResources.length ? _c('div', {
    staticClass: "transport-resources-list"
  }, [_c('vs-table', {
    attrs: {
      "showActionButton": "",
      "rows": _vm.assignedResources,
      "columns": _vm.resourceColumns,
      "showPagination": false,
      "showSearch": false,
      "actionItems": _vm.actionItems
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref) {
        var _row$details, _row$details$type, _row$details$type2, _row$details$category;
        var column = _ref.column,
          row = _ref.row;
        return [column.field === 'name' ? _c('span', [_c('base-resource-label', {
          attrs: {
            "resource": row.details
          }
        })], 1) : column.field === 'type' && (_row$details = row.details) !== null && _row$details !== void 0 && (_row$details$type = _row$details.type) !== null && _row$details$type !== void 0 && _row$details$type.slug ? _c('span', [_vm._v(" " + _vm._s(_vm.$t(`base.global.resourceType.${(_row$details$type2 = row.details.type) === null || _row$details$type2 === void 0 ? void 0 : _row$details$type2.slug}`)) + " ")]) : column.field === 'category' ? _c('span', [_vm._v(" " + _vm._s((_row$details$category = row.details.category) === null || _row$details$category === void 0 ? void 0 : _row$details$category.name) + " ")]) : column.field === 'serial_number' ? _c('span', [_vm._v(" " + _vm._s(row.details.serial_number) + " ")]) : column.field === 'status' ? _c('span', [_c('vs-status-badge', {
          attrs: {
            "data": _vm.$store.getters['base/transport/resourceStatus/getStatusBySlug'](row.status)
          }
        })], 1) : _vm._e()];
      }
    }], null, false, 3162655862)
  })], 1) : _c('div', {
    staticClass: "transport-resources-empty"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "AlertCircleIcon"
    }
  }), _vm._v(" Nie przypisano żadnych zasobów do transportu ")], 1)]), _c('base-transport-add-resource-modal', {
    attrs: {
      "existingIds": (_vm$assignedResources2 = _vm.assignedResources) === null || _vm$assignedResources2 === void 0 ? void 0 : _vm$assignedResources2.map(function (resource) {
        return resource.id;
      })
    },
    on: {
      "ok": _vm.addAssignedResource
    }
  }), _c('base-transport-edit-resource-modal', {
    on: {
      "ok": _vm.updateAssignedResource
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }