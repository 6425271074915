<template>
  <vs-form
    i18nNamespace="form.base.transport"
    :errors="feedback"
  >

    <vs-form-group field="name">
      <b-form-input
        v-model="resource.name"
        placeholder="Nazwa zlecenia transportu"
      />
    </vs-form-group>

    <vs-form-group field="status">
      <base-transport-status-picker
        v-model="resource.status"
        @pick="(status) => selectedStatus = status"
        @input="userChangedStatus"
      />
    </vs-form-group>

    <vs-form-group
      field="crm_provider_id"
      integration="base_transport_company_relation"
    >
      <crm-provider-picker
        v-model="resource.crm_provider_id"
      />
    </vs-form-group>

    <vs-form-group field="base_transport_type_id">
      <base-transport-type-picker
        v-model="resource.base_transport_type_id"
      />

      <template #children>
        <div class="multi-form-group">
          <vs-form-group
            labelCols="12"
            cols="6"
            field="from_investment_id"
            integration="base_transport_investment_relation"
          >
            <investments-investment-picker
              v-model="resource.from_investment_id"
              placeholder="BAZA"
              clearable
            />
          </vs-form-group>

          <vs-form-group
            labelCols="12"
            cols="6"
            field="to_investment_id"
            integration="base_transport_investment_relation"
          >
            <investments-investment-picker
              v-model="resource.to_investment_id"
              placeholder="BAZA"
              clearable
              @pick="(investment) => selectedDestination = investment"
            />
          </vs-form-group>
        </div>

        <vs-form-group
          labelCols="12"
          field="core_branch_id"
        >
          <core-branch-picker
            v-model="resource.core_branch_id"
          />
        </vs-form-group>

        <div class="multi-form-group">
          <vs-form-group
            labelCols="12"
            cols="6"
            field="transport_date"
          >
            <vs-datepicker
              v-model="resource.transport_date"
            />
          </vs-form-group>

          <vs-form-group
            labelCols="12"
            cols="6"
            field="delivery_date"
          >
            <vs-datepicker
              v-model="resource.delivery_date"
            />
          </vs-form-group>
        </div>

        <div class="multi-form-group">
          <vs-form-group
            labelCols="12"
            cols="6"
            field="release_id"
          >
            <core-user-picker
              v-model="resource.release_id"
            />
          </vs-form-group>

          <vs-form-group
            labelCols="12"
            cols="6"
            field="receiver_id"
          >
            <core-user-picker
              v-model="resource.receiver_id"
            />
          </vs-form-group>
        </div>
      </template>
    </vs-form-group>

    <div class="transport-resources">
      <div class="transport-resources-header">
        <vs-form-group
          field="resources"
        >
          <vs-button
            vs-variant="light"
            class="add-transport-resource"
            @click="openAddResourceModal"
          >
            + Dodaj zasób
          </vs-button>
        </vs-form-group>
      </div>

      <div class="transport-resources-list" v-if="assignedResources?.length">
        <vs-table
          showActionButton
          :rows="assignedResources"
          :columns="resourceColumns"
          :showPagination="false"
          :showSearch="false"
          :actionItems="actionItems"
        >
          <template v-slot:row="{ column, row }" >
            <span v-if="column.field === 'name'">
              <base-resource-label :resource="row.details" />
            </span>
            <span v-else-if="column.field === 'type' && row.details?.type?.slug">
              {{ $t(`base.global.resourceType.${row.details.type?.slug}`) }}
            </span>
            <span v-else-if="column.field === 'category'">
              {{ row.details.category?.name }}
            </span>
            <span v-else-if="column.field === 'serial_number'">
              {{ row.details.serial_number }}
            </span>
            <span v-else-if="column.field === 'status'">
              <vs-status-badge
                :data="$store.getters['base/transport/resourceStatus/getStatusBySlug'](row.status)"
              />
            </span>
          </template>
        </vs-table>
      </div>

      <div class="transport-resources-empty" v-else>
        <feather-icon icon="AlertCircleIcon" />
        Nie przypisano żadnych zasobów do transportu
      </div>
    </div>

    <base-transport-add-resource-modal
      :existingIds="assignedResources?.map((resource) => resource.id)"
      @ok="addAssignedResource"
    />

    <base-transport-edit-resource-modal
      @ok="updateAssignedResource"
    />
  </vs-form>
</template>

<script>
import {
  BFormInput,
} from 'bootstrap-vue';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import {
  computed, ref, watchEffect,
} from 'vue';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';
import InvestmentsInvestmentPicker from '@/components/module/investments/InvestmentsInvestmentPicker.vue';
import CoreUserPicker from '@/components/module/core/CoreUserPicker.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import VsTable from '@/components/ui/vs-table/Table.vue';
import CrmProviderPicker from '@/components/module/crm/CrmProviderPicker.vue';
import VsStatusBadge from '@/components/ui/vs-status-badge/VsStatusBadge.vue';
import BaseTransportTypePicker from '@/components/module/base/BaseTransportTypePicker.vue';
import BaseTransportStatusPicker from '@/components/module/base/BaseTransportStatusPicker.vue';
import CoreBranchPicker from '@/components/module/core/CoreBranchPicker.vue';
import BaseTransportAddResourceModal from '../modals/BaseTransportAddResourceModal.vue';
import BaseResourceLabel from '../../resource/BaseResourceLabel.vue';
import BaseTransportEditResourceModal from '../modals/BaseTransportEditResourceModal.vue';

export default {
  name: 'BaseResourceForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    feedback: {
      type: Object,
      default: () => ({}),
    },
    updateStatus: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const resource = computed(() => props.value);
    const assignedResources = ref(resource.value.resources ?? []);
    const addAssignedResource = (assigned) => {
      assignedResources.value.push(assigned);
    };
    const updateAssignedResource = (updated) => {
      assignedResources.value = assignedResources.value
        .map((existing) => {
          if (existing.id !== updated.id) return existing;
          return updated;
        });
    };

    const { modalAddTransportResource, modalEditTransportResource } = useBaseModals();
    const openAddResourceModal = () => {
      modalAddTransportResource.value.open();
    };

    const resourceColumns = [
      { label: 'Nazwa', field: 'name' },
      { label: 'Typ', field: 'type' },
      { label: 'Kategoria', field: 'category' },
      { label: 'Nr ewidencyjny', field: 'serial_number' },
      { label: 'Status', field: 'status' },
    ];

    const actionItems = [
      {
        title: 'Edytuj',
        icon: 'Edit2',
        callback: (obj) => {
          modalEditTransportResource.value.open({ ...obj });
        },
      },
      {
        title: 'Usuń',
        icon: 'Trash',
        callback: (obj) => {
          assignedResources.value = assignedResources.value
            .filter((assigned) => assigned.id !== obj.id);
        },
      },
    ];

    watchEffect(() => emit('resources:update', assignedResources.value
      .map((assigned) => ({
        id: assigned.id,
        status: assigned.status,
        description: assigned.description,
      }))));

    const transportStatusEffects = {
      onTheWay: {
        affects: ['readyToSend', 'delivered', 'notDelivered'],
        replace: 'sent',
      },
      delivered: {
        affects: ['readyToSend', 'sent'],
        replace: 'delivered',
      },
    };

    const selectedStatus = ref(null);
    const userChangedStatus = (slug) => {
      if (!Object.keys(transportStatusEffects).includes(slug)) return;
      const resourceStatusesToChange = transportStatusEffects[slug].affects;

      assignedResources.value = assignedResources.value
        .map((assigned) => {
          if (!resourceStatusesToChange.includes(assigned.status)) return assigned;
          return { ...assigned, status: transportStatusEffects[slug].replace };
        });
    };

    if (props.updateStatus) {
      userChangedStatus(props.updateStatus);
    }

    const selectedDestination = ref(null);

    return {
      resource,
      openAddResourceModal,
      assignedResources,
      addAssignedResource,
      resourceColumns,
      actionItems,
      selectedStatus,
      userChangedStatus,
      updateAssignedResource,
      selectedDestination,
    };
  },
  components: {
    BFormInput,
    VsForm,
    VsFormGroup,
    VsDatepicker,
    InvestmentsInvestmentPicker,
    CoreUserPicker,
    VsButton,
    BaseTransportAddResourceModal,
    BaseResourceLabel,
    VsTable,
    CrmProviderPicker,
    VsStatusBadge,
    BaseTransportTypePicker,
    BaseTransportStatusPicker,
    BaseTransportEditResourceModal,
    CoreBranchPicker,
  },
};
</script>

<style lang="scss" scoped>
.transport-resources {
  width: 100%;

  &-header {
    border-bottom: 1px solid var(--vs-card-border);
  }

  &-empty {
    padding: 40px 0 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-weight: 500;
    opacity: 0.5;
    user-select: none;
  }

  &-list {
    .resource {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .add-transport-resource {
    display: block;
    margin-left: auto;
  }
}

.multi-form-group {
  display: flex;
  align-items: flex-start;
  gap: 5px;

  &::v-deep {
    .b-form-group {
      width: calc(100% - 5px);
    }
  }
}
</style>
