<template>
  <vs-modal
    :modalInstance="modalEditTransport"
    @ok="createResource"
  >
    <base-transport-form
      v-model="fields"
      :feedback="feedback"
      @resources:update="(resources) => fields.resources = resources"
      :updateStatus="updateStatus"
    />
  </vs-modal>
</template>

<script>
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import useValidatedForm from '@/hooks/useValidatedForm';
import useBaseApi from '@/hooks/base/useBaseApi';
import showToast from '@/libs/toasts';
import { UpdateTransportError, UpdateTransportSuccess } from '@/libs/toasts/feedback/module/base';
import { ref } from 'vue';
import BaseTransportForm from '../forms/BaseTransportForm.vue';

export default {
  name: 'BaseTransportCreateModal',
  emits: ['ok'],
  setup(_, { emit }) {
    const { modalEditTransport } = useBaseModals();

    // TODO: delete
    const updateStatus = ref(null);

    const {
      fields,
      feedback,
      areAllFieldsValid,
      assignServerSideErrors,
      resetFields,
      assignFields,
    } = useValidatedForm({
      id: 'required',
      name: 'required',
      status: 'required',
      from_investment_id: '',
      to_investment_id: '',
      crm_provider_id: 'required',
      core_branch_id: 'required',
      base_transport_type_id: 'required',
      release_id: 'required',
      receiver_id: 'required',
      transport_date: 'required',
      delivery_date: 'required|date_after:@transport_date',
      resources: '',
    });

    modalEditTransport.value.on({
      open(data) {
        updateStatus.value = null;
        const resources = data.resources ?? [];

        resetFields();
        assignFields({
          ...data,
          resources: resources.map((res) => ({
            id: res.pivot.base_resource_id,
            status: res.pivot.status,
            description: res.pivot.description,
            details: res,
          })),
        });

        if (data.updateResourceStatuses) {
          updateStatus.value = data.status;
        }
      },
    });

    const createResource = async () => {
      if (!(await areAllFieldsValid())) return;

      modalEditTransport.value.isLoading = true;

      const sendData = {
        ...fields.value,
        resources: fields.value?.resources.map((resource) => ({
          id: resource.id,
          status: resource.status,
          description: resource.description,
        })),
      };

      useBaseApi()
        .updateTransport(fields.value.id, {
          ...sendData,
        })
        .then(({ data }) => {
          showToast(UpdateTransportSuccess, { name: data.data?.name });
          modalEditTransport.value.close();
          emit('ok', data.data);
          modalEditTransport.value.notifyObservers('success');
        })
        .catch(({ response }) => {
          showToast(UpdateTransportError);
          assignServerSideErrors(response?.data?.errors);
        })
        .finally(() => {
          modalEditTransport.value.isLoading = false;
        });
    };

    return {
      modalEditTransport,
      fields,
      feedback,
      createResource,
      updateStatus,
    };
  },
  components: {
    VsModal,
    BaseTransportForm,
  },
};
</script>
